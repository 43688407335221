

































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import __ from '@/helpers/__'
import { routeList } from './routeList'
import { GeneralRoutes } from '@/router/routes/general'
import User from '@/modules/user/models/user.model';
import { mapGetters } from 'vuex';
import { UserGetters } from '@/store/modules/user/user.getters';
import http from '@/shared/helpers/http';
import { UserActions } from '@/store/modules/user/user.actions';
import { AuthRoutes } from '@/router/routes/auth';
import UserDataModal from '@/shared/components/UserDataModal.vue';
import hasPermission from '@/shared/helpers/has-permission';
import { RouteChild } from '@/shared/interfaces/route-info.interface';

@Component({
  components: { UserDataModal },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  },
  methods: {
    __,
    hasPermission
  }
})
export default class Sidebar extends Vue {
  @Prop({ default: null }) sidebarStatus?: boolean | null
  routes = routeList
  GeneralRoutes = GeneralRoutes
  user!: User
  company = ''

  mounted():void {
    this.company = process.env.VUE_APP_COMPANY || 'wi'
  }

  get logo(): string {
    if (this.company === 'wedev') {
      return require('@/assets/images/logo-wedev.svg');
    }
    return require('@/assets/images/main-logo.svg');
  }

  childrenHavePermission(children: RouteChild[]) {
    let parentHasPermission = false
    children.forEach(child => {
      if (hasPermission(child.permission)) {
        parentHasPermission = true
      }
    })
    return parentHasPermission
  }

  toggleSidebar():void {
    this.$root.$emit('sidebarStatus', !this.sidebarStatus)
  }

  collapseMobileSidebar(): void {
    if (window.innerWidth < 992) {
      this.$root.$emit('sidebarStatus', true)
    }
  }

  get userInitials(): string {
    if (!this.user) return ''
    return this.user.firstName.substring(0, 1) + this.user.lastName.substring(0, 1)
  }

  logout(): void {
    http
      .post('auth/logout')
      .then(() => {
        this.$store.dispatch(UserActions.logout)
        this.$router.push({ name: AuthRoutes.login })
      })
      .catch(err => {
        console.log('err = ', err)
      })
  }

  editUserData(): void {
    const ref: any = this.$refs.userModal
    ref.editProfile()
  }
}
